/* ACE PAGE CSS */
.main_div_ace {
  background-image: url("./assets/images/ACE_BG.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #fcf7ff;
}

.main_div_ace_2 {
  background-image: url("./assets/images/ACE_BG_1.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 80px;
}

.ace_box_1 {
  margin-left: 32%;
}

.ace_box_5 {
  margin-left: 30%;
}

.input_span {
  color: #fe0000;
}

.ace_h1 {
  font-family: BebasNeue;
  font-size: 54px;
  font-weight: 400;
  color: #2b2b2b;
  line-height: 50px;
}

.react-tel-input .form-control {
  width: 100% !important;
}

.ace_h1_1 {
  font-family: BebasNeue;
  font-size: 45px;
  font-weight: 400;
  color: #fcd01e;
  line-height: 43px;
}

.ace_h1_2 {
  font-family: BebasNeue;
  font-weight: 400;
  color: #2b2b2b;
  line-height: 43px;
}

.ace_p23:hover {
  color: blue;
}

.ace_h1_24 {
  font-family: ManropeRegular;
  color: #2b2b2b;
  background-color: #f5f5f5;
  width: 40px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.ace_h1_24:hover {
  color: white;
  background-color: #f2bcbc;
}

.ace_h1_21 {
  font-family: BebasNeue;
  font-weight: 400;
  color: #2b2b2b;
  line-height: 43px;
}

.ace_h4_1 {
  font-family: BebasNeue;
  font-weight: 400;
  color: #fcd01e;
}

.ace_h45 {
  font-family: BebasNeue;
  font-weight: 400;
  color: #0598f3;
}

.ace_span {
  font-family: BebasNeue;
  font-weight: 400;
  color: #fe0000;
}

.ace_span22 {
  font-family: BebasNeue;
  font-weight: 400;
}

.ace_p {
  font-family: "ManropeRegular";
  font-size: 16px;
  font-weight: 400;
  color: #4b4b4b;
}

.ace_box_2 {
  margin-left: 16%;
  margin-right: 16%;
}

.ace_box_3 {
  margin-left: 25%;
}

.dropdown-menu {
  margin-top: 10% !important;
}

.ace_h4 {
  font-family: UrbanistSemiBold;
  font-weight: 400;
  font-size: 26px;
}

.ace_p_1 {
  font-family: "ManropeRegular";
  font-weight: 400;
  color: #4b4b4b;
  font-size: 12px;
}

.ace_p_5 {
  font-family: Roboto-Regular;
  font-weight: 400;
  color: #4b4b4b;
  font-size: 10px;
  width: 68%;
}

.ACEManagementIMG {
  width: 110%;
}

.bgACE {
  background-color: #f7fcff !important;
}

.ace_box_4 {
  height: 200px;
}

.ace_p_wid {
  width: 80%;
}

/* __________________________________________ */
.sym_feature_box {
  border: 1px solid #ffffff40;
  border-radius: 20px;
  cursor: pointer;
  background: linear-gradient(55.36deg,
      rgba(88, 64, 186, 0.35) -1.94%,
      rgba(40, 29, 84, 0.35) 57.88%);
  background-color: #060a12;
  color: white;
}

.sym_feature_box:hover {
  color: #0598f3;
}

.input_span {
  color: red;
}

.pragraphStyMission {
  font-size: 14px;
}

/* Footer Section */
.footer_para {
  font-size: 14px;
  width: 85%;
  /* line-height: 21.86px; */
  font-family: "ManropeRegular";
  color: var(--navbar-link-color);
}

.footer_para_1:hover {
  color: var(--navbar-active-color) !important;
}

.footer_para_mail {
  font-size: 12px;
  width: 85%;
  /* line-height: 21.86px; */
  font-family: "ManropeRegular";
  color: var(--navbar-link-color);
}

.footer_para_mail:hover {
  color: var(--navbar-active-color) !important;
}

.heading_decoration_none {
  font-size: 14px;
  /* line-height: 13.86px; */
  font-family: "ManropeRegular";
  color: var(--navbar-link-color);
}

.footer_para_1 {
  font-size: 14px;
  /* line-height: 13.86px; */
  font-family: "ManropeRegular";
  color: var(--navbar-link-color);
}

.footer_para_2:hover {
  color: var(--navbar-active-color);
}

.footer_para_2 {
  font-size: 14px;
  font-family: "ManropeRegular";
  color: var(--navbar-link-color);
}

.circle-div {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid var(--navbar-link-color);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--navbar-link-color);
}

.circle-div:hover {
  background-color: #fcd01e;
}

.visiblty {
  visibility: hidden;
}

.footer_h5 {
  color: var(--navbar-link-color);
  font-size: 15px;
  /* line-height: 21.86px; */
  font-family: "ManropeRegular";
}

.footer_hr {
  border-top: 1px solid var(--text-light-gray);
  margin: 0;
  padding: 0;
}

.bottom_footer_flex {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.footer_main_div {
  background-color: var(--footer-background-color);
}

.footer_para_4 {
  font-size: 14px;
  /* line-height: 21.86px; */
  font-family: "ManropeRegular";
  color: var(--navbar-link-color);
}

/* _____________________________________ */

/* About Page */
.about_main_div {
  background-image: url("./assets/images/redback.webp");
  background-position: center;
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.aboutpage_h3.careerText {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.aboutpage_h3 {
  color: var(--navbar-link-color);
  font-family: UrbanistReg;
}

.aboutpage_para {
  color: var(--navbar-link-color);
  font-family: "ManropeRegular";
  font-size: 14px;
}

.missionVission {
  border-radius: 19.383px;
  border: 4px solid rgba(232, 232, 232, 0.6);
  background-color: #f7fcff;
  padding: 20px;
}

.missionVission:hover {
  box-shadow: 0px 18px 59.3px 0px rgba(0, 0, 0, 0.1);
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  /* Adjust spacing between tags as needed */
  max-width: 100%;
}

.tags-container h4 {
  margin: 0;
  /* Remove default margin from h4 */
  padding: 8px;
  /* Add padding for spacing around text */
  flex: 0 1 auto;
  /* Allow tags to shrink and wrap */
  white-space: nowrap;
  /* Prevent text from wrapping within each tag */
  cursor: pointer;
}

.flag_border {
  border: 3px solid white;
  width: 100%;
  height: 40px;
}

.flag_back_div {
  width: 100%;
  padding: 5px;
}

.globalizedSty {
  font-size: 12px;
  font-weight: 400;
  font-family: "ManropeRegular";
  border-left: 2.5px solid #0a76db;
  padding-left: 8px;
}

/* ___________________________________________ */

.Our_Team_box {
  border: 6px solid#EEEEEE;
  position: relative;
  border-radius: 14px;
  box-shadow: 0px 18px 59.29999923706055px 0px #00000026;
  height: 100%;
}

.team_images {
  width: 100%;
  height: auto;
}

.Team_Details_box {
  position: absolute;
  background-color: white;
  border-radius: 6px;
  width: 90%;
  bottom: 5%;
  left: 5%;
}

.Team_Details_box h6 {
  font-family: UrbanistBold !important;
  text-align: center;
  font-weight: bold;
  margin-bottom: 0px;
  font-size: 16px;
}

.Team_Details_box p {
  font-family: ManropeBold !important;
  text-align: center;
  font-size: 9px;
  margin-bottom: 0px;
  font-weight: 600;
  color: #06558c;
}

.heading_Sty_har {
  font-size: 35px;
  font-family: UrbanistReg;
  font-weight: 300;
  width: 75%;
  /* line-height: 38px; */
}

.pragraph_Sty_para {
  font-size: 13px;
  font-weight: 500;
  font-family: "ManropeRegular";
}

.swati_Icon_bg {
  position: absolute;
  left: 2%;
  width: 70%;
}

.pragraphSty.whyChoose {
  font-size: 13px !important;
  font-family: ManropeRegular;
  font-weight: 500;
}

.homeFeature_p_2 {
  font-family: Questrial;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
}

.homeFeature_p_8 {
  font-family: ManropeRegular;
  font-size: 9.5px;
  height: 50px;
  font-weight: 300;
  color: #a3a3a3;
}

.topics p {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}

.news-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.news-item {
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  margin: 8px;
  max-width: 600px;
  width: 100%;
  text-align: left;
}

.news-item img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.news-item h3 {
  margin: 12px 0;
}

.news-item p {
  color: #555;
}

.countryBack {
  background-image: url("./assets/images/redback.webp");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 400px;
}

.pakage_investor_back {
  background-image: url("./assets/images/redback.webp");
  background-position: center;
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.investorpaymentback {
  background-image: url("./assets/images/redback.webp");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 400px;
}

.blogsetimg {
  height: 200px;
  width: 100%;
}

.highlight {
  background-color: yellow;
}

.box_topics {
  border: 2px solid grey;
  font-family: ManropeRegular;
  color: #2b2b2b;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.dropdown_menu_country {
  border: 2px solid grey;
  background-color: #fff2f2;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  width: 70%;
  border-radius: 5px;
  margin-top: 2%;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 200px;
}

/* Webkit-based browsers */
.dropdown_menu_country::-webkit-scrollbar {
  width: 10px;
}

.dropdown_menu_country::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Background of the scrollbar track */
  border-radius: 5px;
}

.dropdown_menu_country::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Color of the scrollbar thumb */
  border-radius: 5px;
  border: 2px solid #fff2f2;
  /* Adds a border around the thumb */
}

.dropdown_menu_country::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* Color of the scrollbar thumb when hovered */
}

/* Firefox */
.dropdown_menu_country {
  scrollbar-width: thin;
  /* Makes the scrollbar thinner */
  scrollbar-color: #fe0000 #f1f1f1;
  /* Thumb color and track color */
}

.advertiseform_para {
  color: #4b4b4b;
  font-family: ManropeRegular;
  font-size: 14px;
  font-weight: 400;
}

.advertiseform_para1 {
  font-family: ManropeRegular;
  font-size: 14px;
  font-weight: 400;
}

.advertiseform_para2 {
  color: grey;
  font-family: ManropeRegular;
  font-size: 14px;
  font-weight: 400;
}

/* _______________ Home Page _______________ */
.section-search {
  background: #f9f9f9 url(./assets/images/5664443.jpg) no-repeat bottom center;
  min-height: 520px;
  background-size: 100% auto;
  background-blend-mode: Darken;
}

.h3_home_cscps {
  color: #fe0000;
  font-family: UrbanistBold !important;
}

.h1_home_cscps {
  font-family: ManropeBold;
  font-size: 60px;
}

.p_home_cscps {
  font-family: ManropeRegular;
  color: dark;
  font-size: 20px;
}

.search-box form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.search-box .form-control {
  border: 1px solid #ccc;
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075);
  border-radius: 5px;
  padding-left: 35px;
}

.search-box .search-location {
  -ms-flex: 0 0 240px;
  flex: 0 0 240px;
  margin-right: 12px;
  position: relative;
  width: 240px;
}

.search-info {
  width: 60%;
}

.search-location .form-control {
  background: #fff url(./assets/images/location.png) no-repeat 10px center;
}

.search-info .form-control {
  background: #fff url(./assets/images/search.png) no-repeat 10px center;
}

.search-box .form-text {
  color: #757575;
  font-size: 13px;
  font-family: ManropeRegular;
}

/* _______________Investor Portal _______________ */
.investor_cscps_main_div {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 10px;
  height: 90vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
}

.investor_cscps_main_div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-image: url("./assets/images/investor_back.jpeg"); */
  background-size: cover;
  background-repeat: no-repeat;
  /* opacity: 0.8;  */
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.investor_cscps_main_div .container-fluid {
  position: relative;
  width: 70%;
  border-radius: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.investor_cscps_h6 {
  font-family: ManropeBold;
}

.investor_cscps_h1 {
  font-family: ManropeBold;
  font-size: 50px;
}

.investor_cscps_p {
  font-family: ManropeRegular;
}

.investor_dropdown_name {
  font-family: ManropeRegular;
  font-size: 15px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.reacttyped_heading {
  font-size: 50px;
}

/* _______________Investor Portal _______________ */
.investor_cscps_main_div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("./assets/images/investor_back.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* opacity: 0.8;  */
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.investor_cscps_main_div .container-fluid {
  position: relative;
  width: 70%;
  border-radius: 20px;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

/* _______________Franchise Portal _______________ */
.partner_cscps_main_div {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 10px;
  height: 90vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
}

.partner_cscps_main_div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("./assets/images/partner_back.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* opacity: 0.8;  */
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.partner_cscps_main_div .container-fluid {
  position: relative;
  width: 70%;
  border-radius: 20px;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

/* _______________Franchise Portal _______________ */
.franchise_cscps_main_div {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 10px;
  height: 90vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
}

.franchise_cscps_main_div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("./assets/images/franchise_back.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* opacity: 0.8;  */
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.franchise_cscps_main_div .container-fluid {
  position: relative;
  width: 70%;
  border-radius: 20px;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

/* _______________Advertise Portal _______________ */
.advertise_cscps_main_div {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 10px;
  height: 90vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
}

.advertise_cscps_main_div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-image: url("./assets/images/advertise_back.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  /* opacity: 0.8;  */
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.advertise_cscps_main_div .container-fluid {
  position: relative;
  width: 70%;
  border-radius: 20px;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

/* _______________Blog Portal _______________ */
.blog_cscps_main_div {
  position: relative;
  display: flex;
  background-position: center;
  align-items: center;
  border-radius: 10px;
  height: 90vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
}

.blog_cscps_main_div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-image: url("./assets/images/blog_back.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  /* opacity: 0.8;  */
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.blog_cscps_main_div .container-fluid {
  position: relative;
  width: 70%;
  border-radius: 20px;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

/* _______________Education Portal _______________ */
.eductaion_cscps_main_div {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 10px;
  height: 90vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
}

.eductaion_cscps_main_div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-image: url("./assets/images/education_back.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  /* opacity: 0.8;  */
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.eductaion_cscps_main_div .container-fluid {
  position: relative;
  width: 70%;
  border-radius: 20px;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

/* _______________News Portal _______________ */
.news_cscps_main_div {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 10px;
  height: 90vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
}

.news_cscps_main_div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-image: url("./assets/images/medical_back.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  /* opacity: 0.8;  */
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.news_cscps_main_div .container-fluid {
  position: relative;
  width: 70%;
  border-radius: 20px;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

/* _______________SEO Portal _______________ */
.seo_cscps_main_div {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 10px;
  height: 90vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
}

.seo_cscps_main_div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-image: url("./assets/images/seo_back.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  /* opacity: 0.8;  */
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.seo_cscps_main_div .container-fluid {
  position: relative;
  width: 70%;
  border-radius: 20px;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

/* _______________Ecommerce Portal _______________ */
.ecommerce_cscps_main_div {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 10px;
  height: 90vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
}

.ecommerce_cscps_main_div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-image: url("./assets/images/ecommerce_back.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  /* opacity: 0.8;  */
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.ecommerce_cscps_main_div .container-fluid {
  position: relative;
  width: 70%;
  border-radius: 20px;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

/* ___________Country_____________________ */
.h3_country_cscps {
  color: white;
  font-family: CinzelBold !important;
}

.h2_country_cscps {
  color: white;
  font-family: CinzelBold !important;
}

.h1_country_cscps {
  color: #2b2b2b;
  font-family: CinzelBold !important;
}

.h3_country1_cscps {
  color: #fe0000;
  font-family: CinzelBold !important;
}

.ol_country_cscps {
  color: #2b2b2b;
  font-family: ManropeBold !important;
}

.p2_country_cscps {
  color: #2b2b2b;
  font-size: 15px;
  font-family: ManropeRegular !important;
}

.h11_country_cscps {
  font-family: BebasNeue;
  font-weight: 400;
  color: #2b2b2b;
}

.company_back_img {
  background-image: url(./assets/images/company_back.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
}

.ace_h46 {
  font-family: MerriweatherBold;
}

.logo_shadow {
  box-shadow: 0 4px 50px rgba(255, 255, 255, 0.7);
  display: inline-flex;
  border-radius: 10px;
}

.blog_back_img {
  background-image: url(./assets/images/blog_back1.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
}

.hero_new_h1 {
  font-size: 35px;
}

.ace_span24 {
  font-family: CinzelBold;
  font-size: 65px;
}

.ace_span36 {
  font-family: CinzelBold;
  font-size: 54px;
  color: #fe0000;
}

.ace_span12 {
  color: #fe0000;
  font-family: MerriweatherBold;
}

.signup_heading {
  font-family: CinzelBold;
}

/* MEDIA QUERY */
@media (min-width: 2000px) {
  .Team_Details_box p {
    font-size: 16px;
  }

  .Team_Details_box h6 {
    font-size: 26px;
  }

  .swati_Icon_bg {
    width: 80%;
  }

  .heading_Sty_har {
    font-size: 20px;
    font-family: UrbanistReg;
    font-weight: 300;
    width: 100%;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .contactpage_p_1 {
    font-size: 9.8px !important;
  }

  .footer_para_space {
    height: 55px;
  }
}

@media (max-width: 1024px) {
  .Team_Details_box p {
    font-size: 7px;
  }

  .Team_Details_box h6 {
    font-size: 13px;
  }

  .swati_Icon_bg {
    width: 100%;
  }

  .heading_Sty_har {
    font-size: 20px;
    font-family: UrbanistReg;
    font-weight: 300;
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1224px) {
  .ace_h1 {
    font-size: 35px;
    line-height: 33px;
  }

  .ace_h4 {
    font-size: 15px;
  }

  .ace_p_1 {
    font-size: 9px;
  }
}

@media screen and (max-width: 768px) {
  .ace_box_1 {
    margin-left: 5%;
    margin-top: 15%;
  }

  .ace_box_5 {
    margin-left: 5%;
    margin-top: 15%;
  }

  .ace_box_3 {
    margin-left: 5%;
  }

  .ACEManagementIMG {
    width: 100%;
  }

  .ace_h4 {
    font-size: 25px;
  }

  .ace_p_1 {
    font-size: 9px;
  }

  .ace_p_5 {
    font-size: 9px;
    width: 85%;
  }

  .footer_para_2 {
    font-size: 11px;
    font-family: "ManropeRegular";
    color: var(--navbar-link-color);
  }

  .footer_para {
    font-size: 12px;
    width: 100%;
    font-family: "ManropeRegular";
    color: var(--navbar-link-color);
  }

  .footer_hr {
    margin-left: 12px;
    margin-right: 12px;
  }

  .swati_Icon_bg {
    width: 100%;
  }

  .heading_Sty_har {
    font-size: 20px;
    font-family: UrbanistReg;
    font-weight: 300;
    width: 100%;
  }
}

@media (max-width: 576px) {
  .p2_country_cscps {
    font-size: 14px;
  }

  .section-search {
    background: #f9f9f9 url(./assets/images/5664443.jpg) no-repeat bottom center;
    min-height: 400px;
  }

  .search-info {
    width: 100%;
  }

  .h1_home_cscps {
    font-size: 27px;
  }

  .p_home_cscps {
    font-size: 13px;
  }

  .investor_cscps_h6 {
    font-size: 14px;
  }

  .investor_cscps_h1 {
    font-size: 20px;
  }

  .investor_cscps_p {
    font-size: 13px;
  }

  .investor_cscps_main_div .container-fluid {
    width: 95% !important;
  }

  .ecommerce_cscps_main_div .container-fluid {
    width: 95% !important;
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .franchise_cscps_main_div .container-fluid {
    width: 95% !important;
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .partner_cscps_main_div .container-fluid {
    width: 95% !important;
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .advertise_cscps_main_div .container-fluid {
    width: 95% !important;
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .blog_cscps_main_div .container-fluid {
    width: 95% !important;
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .eductaion_cscps_main_div .container-fluid {
    width: 95% !important;
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .seo_cscps_main_div .container-fluid {
    width: 95% !important;
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .seo_cscps_main_div .container-fluid {
    width: 95% !important;
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .news_cscps_main_div .container-fluid {
    width: 95% !important;
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .reacttyped_heading {
    font-size: 37px;
  }

  .Team_Details_box {
    border-radius: 6px;
  }

  .Team_Details_box h6 {
    font-size: 11px;
  }

  .pragraphStyMission {
    font-size: 16px;
  }

  .Team_Details_box p {
    font-size: 6px;
  }

  .swati_Icon_bg {
    width: 80%;
    position: absolute;
    left: 18%;
    width: 70%;
  }

  .heading2Sty.homePage {
    font-size: 16px !important;
    font-family: "UrbanistSemiBold" !important;
  }

  .pragraphSty.Innovation,
  .pragraphSty.transparency,
  .pragraphSty.whyChoose {
    font-size: 11px !important;
  }

  .heading_Sty_har {
    font-size: 17px !important;
    font-family: UrbanistReg;
    font-weight: 300;
    width: 100%;
    text-align: center !important;
  }

  .ace_box_1 {
    margin-left: 5%;
    margin-top: 15%;
  }

  .ace_h1_24 {
    font-size: 10px;
    width: 20px;
    display: flex;
    justify-content: center;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .ace_box_5 {
    margin-left: 5%;
    margin-top: 15%;
  }

  .ace_h1 {
    font-size: 30px;
    line-height: 30px;
  }

  .ace_h1_1 {
    font-size: 22px;
    line-height: 27px;
  }

  .ace_box_3 {
    margin-left: 0%;
  }

  .eye_btn_mobil {
    display: none;
  }

  .main_div_ace_2 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .ace_box_2 {
    margin-left: 5%;
    margin-right: 5%;
  }

  .ace_p_wid {
    width: 100%;
  }

  .ace_h1_2 {
    line-height: 33px;
  }

  .ace_h1_21 {
    line-height: 33px;
  }

  .ace_p {
    font-size: 10px;
  }

  .aboutpage_h3 {
    color: var(--navbar-link-color);
    font-family: UrbanistReg;
    font-size: 29px !important;
  }

  .aboutpage_para {
    color: var(--navbar-link-color);
    font-family: "ManropeRegular";
    font-size: 11px;
  }

  .missionVission {
    border: none !important;
  }

  .missionVission:hover {
    box-shadow: unset;
  }

  .missionVission.ourMission {
    border-radius: 19.383px;
  }

  .dropdown_menu_country {
    width: 85%;
  }

  .mobile_button_center {
    display: flex;
    justify-content: center;
  }

  .ace_span24 {
    font-size: 45px;
    text-align: center;
  }
  .hero_new_h1 {
    font-size: 33px;
  }
}

@media (max-width: 330px) {
  .h1_home_cscps {
    font-size: 18px;
  }

  .pragraphSty.whyChoose {
    font-size: 9px !important;
  }

  .investor_dropdown_name {
    font-size: 17px;
  }

  .drop_country {
    margin: 4px;
    padding: 12px 25px;
  }
}




.modal {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
}

.modal.show {
  display: flex;
}

.modal_content {
  background-color: white;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 18px;
  cursor: pointer;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.dropdown_toggle {
  white-space: normal;
  text-align: center;
}

.drop_country33 {
  background-color: transparent;
  border: 3px solid transparent;
  outline: none;
}

.drop_country33:hover {
  background-color: transparent;
  border-bottom: 3px solid #fe0000;
  outline: none;
}

.dropdown_menu33 {
  margin-top: 0% !important;
}

/* SIDEBAR */
.app-container {
  display: flex;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 250px;
  background: rgb(63, 60, 60) !important;
}

.ace_h1_101 {
  font-family: BebasNeue;
  font-size: 45px;
  font-weight: 400;
  color: white;
}

.ace_h1_102 {
  font-family: BebasNeue;
  font-size: 30px;
  font-weight: 400;
  color: #fe0000;
}

.ace_h1_103 {
  font-family: CinzelBold;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 400;
  color: white;
}

.ace_h1_95 {
  font-family: CinzelBold;
  text-transform: uppercase;
  font-weight: 400;
  color: black;
}

.main-content {
  margin-left: 250px;
  width: calc(100% - 200px);
  display: flex;
  flex-direction: column;
}

.content-area {
  padding: 20px;
  flex: 1;
}

.first .nav-link {
  cursor: pointer;
  font-family: ManropeRegular;
  font-size: 18px;
  color: white;
}

.first .nav-item .nav-link:hover {
  font-family: ManropeRegular;
  font-size: 18px;
  color: rgb(60, 57, 57);
}

.first .nav-item .nav-link.active {
  color: black;
  background-color: #dada43 !important;
}


.first .nav-item .nav-link:hover {
  background-color: lightblue;
  /* or any color you prefer */
}


.mdb-datatable-filter {
  margin-top: 5% !important;
}

/* CustomAlert.css */
.custom-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.custom-alert-box {
  background: rgb(241, 236, 236);
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.custom-alert-message {
  font-size: 18px;
  /* Increase the font size */
  margin-bottom: 20px;
}

.box_portals {
  cursor: pointer;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  transition: transform 0.3s ease-in-out;
}
.box_portals:hover {
  transform: translateY(-10px);
}
.box_portals_h3 {
  color: #2b2b2b;
  font-family: UrbanistBold !important;
}